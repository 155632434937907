import styles from "./VoiceDeviceCard.module.scss"
import { deviceImages, deviceStatus } from "../../constants/images";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { selectDevice, getDeviceVoiceData, getControls, refreshDeviceStatus, getSelectedDeviceData } from "../../redux/voiceControl-slice";
import { useEffect } from "react";
import { getModelName } from "../../utils";

export function VoiceDeviceCard({ device }) {

    const deviceSelected = useSelector(getSelectedDeviceData)
    const deviceData = useSelector(getDeviceVoiceData(device.cloudId))
    const dispatch = useDispatch();

    const isActive = device.cloudId === deviceSelected.cloudId;

    function isSelected() {
        return isActive ? styles.deviceSelected : null
    }
    
    useEffect(() => {
        if(isActive && deviceData?.status === ""){
            dispatch(refreshDeviceStatus(device.cloudId))
        }
    })

    function onSelectDevice(device) {
        dispatch(selectDevice(device))

        if(deviceData.status === "ok" && isActive){
            dispatch(getControls(device.cloudId))
        }
    }
    return (
        <div className={cn(styles.voiceControlDeviceCard, isActive ? styles.selected : null)} onClick={() => { onSelectDevice(device) }}>
            <div className={styles.deviceImage}>
                <img src={deviceImages[device.model]} alt={device.model}/>
            </div>
            <div className={styles.deviceInfo}>
                <div className={styles.deviceModel}>
                    {getModelName(device.model)}
                </div>
                <div className={styles.deviceFriendlyName}>
                    {getModelName(device.model, device.friendly_name)}
                </div>
            </div>
            <div className={cn(styles.deviceStatus, isSelected())}>
                    {deviceStatus[deviceData?.status]}
            </div>
        </div>
    )
}