import styles from "./DeviceCardListView.module.scss";
import { ReactComponent as SettingsIcon } from "../../assets/icons/device-setting-icon.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/device-media-control.svg";
import { ReactComponent as VoiceControlIcon } from "../../assets/icons/micro-icon.svg";
import { Link } from "../../components";
import { deviceImages } from "../../constants/images";
import { oemValues } from "../../constants/oem";
import { isDeviceClickable, getModelName } from "../../utils";
import { useDispatch } from "react-redux";
import { selectDevice } from "../../redux/voiceControl-slice";

export function DeviceCardListView({ device }) {
  const dispatch = useDispatch()
  function handleDeviceCardClick() {
    window.open(`https://${device.cloudId}.${oemValues.cloudDomain}`, "_blank");
  }

  return (
    <div className={styles.deviceCard}>
      <div className={styles.deviceCardWrapper}>
        <div className={styles.deviceImage}>
          <img src={deviceImages[device.model]} alt="device-list-view" />
        </div>
        <div className={styles.deviceInfo}>
          <div className={styles.deviceModel}>{getModelName(device.model)}</div>
          <div className={styles.deviceName}>
            {getModelName(device.model, device.friendly_name)}
          </div>
        </div>
        <div className={styles.deviceBtnGroup}>
          <Link
            to={`/device-settings/${device.cloudId}`}
            className={styles.deviceIconLink}
          >
            <SettingsIcon className={styles.deviceSettingsIcon} />
          </Link>
          {!isDeviceClickable(device.model) && (
            <PlayIcon
              className={styles.devicePlayIcon}
              onClick={handleDeviceCardClick}
            />
          )}
          {oemValues.voiceCompatible.includes(device.model) && (
            <Link
              to={`/voice`}
              onClick={(e) => {
                dispatch(selectDevice(device))
                e.stopPropagation();
              }}
              className={styles.deviceIconLink}
            >
              <VoiceControlIcon className={styles.deviceMicroIcon} />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
