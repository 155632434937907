import React, { useCallback, useEffect, useState } from "react";
import { StatusPage } from ".";
import { useFormatMessage } from "../i18n";
import { useDispatch } from "react-redux";
import { confirmDeleteUser, logout } from "../redux/auth-slice";
import { redirectToLogin } from "../services/auth-service";
import { getQueryParam } from "../utils";

export function ConfirmDeletePage() {
  const tr = useFormatMessage();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("");
  const confirmation_code = getQueryParam("cpcode");

  const handleSubmit = useCallback(
    async (confirmation_code) => {
      const data = await dispatch(confirmDeleteUser({ confirmation_code }));

      if (data.meta.requestStatus === "fulfilled") {
        dispatch(logout());
        setMessage(tr("we_confirm_your_account_has_been_deleted"));
        setVariant("success");
      } else {
        setVariant("error");
        setMessage(tr("sorry_but_we_cannot_confirm_your_account_deletion"));
      }
    },
    [dispatch, tr]
  );

  useEffect(() => {
    if (confirmation_code) {
      handleSubmit(confirmation_code);
    } else {
      redirectToLogin();
    }
  }, [confirmation_code, handleSubmit]);

  return (
    <>
      <StatusPage text={message} variant={variant} />
    </>
  );
}
