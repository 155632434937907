import qs from "qs";
import md5 from "md5";
import { devicesWithoutPlayIcon } from "./constants/images";
import { oemValues } from "./constants/oem";
import sharedErrorStyles from "./pages/components/shared.module.scss";
import { AVATAR_SIZE } from "./constants/constants";

export function getQueryParam(name, fallback) {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const value = query[name];
  if ((value === null || value === undefined) && fallback !== undefined)
    return fallback;
  if (Array.isArray(value)) return value[0];
  return value;
}

export function isDeviceClickable(model) {
  return devicesWithoutPlayIcon.includes(model);
}

export function getModelName(model, name) {
  if (name) return name;

  model = oemValues.modelNameMap[model] || model;

  // Prefix is only used when friendly name not specified
  // i.e. when we are expected to always return the model name
  if (typeof name === "undefined" && oemValues.modelPrefix) {
    return oemValues.modelPrefix + " " + model;
  } else {
    return model;
  }
}

export function scrollToFirstValidationError(elem) {
  const firstError = (elem || document).querySelector(
    `.${sharedErrorStyles.errorLabel}`,
  );
  if (!firstError) return;
  firstError.scrollIntoView({ behavior: "smooth" });
}

export function generateGravatarUrl(email) {
  if (!email) email = "DUMMY_EMAIL_PLACEHOLDER";
  // If an image URL is used it must be publicly available;
  // won't work on localhost
  // https://www.gravatar.com/site/implement/images/
  const defaultAvatar = oemValues.customAvatar
    ? `${process.env.REACT_APP_BASE_URL}/default-avatar.png`
    : "mp";
  const hash = md5(email.trim().toLowerCase());
  const params = qs.stringify({ s: AVATAR_SIZE * 2, d: defaultAvatar });
  return `https://www.gravatar.com/avatar/${hash}?${params}`;
}

export function getControlZone(controlId) {
  return parseInt(controlId.slice(0, -2));
}

export function getControlSection(controlId) {
  return parseInt(controlId.slice(-2, -1));
}
