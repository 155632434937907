import { authReducer } from "./auth-slice";
import { devicesReducer } from "./devices-slice";
import { settingsReducer } from "./settings-slice";
import { voiceControlReducer } from "./voiceControl-slice";
import { combineReducers } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";

export const rootReducer = combineReducers({
  auth: authReducer,
  devices: devicesReducer,
  settings: settingsReducer,
  voiceControl: voiceControlReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})