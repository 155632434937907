const oemValuesMap = {
  iddero: {
    cloudDomain: "iddero.net",
    modelPrefix: "Iddero",
    modelNameMap: {
      VERIP: "VERSO+IP",
      VERIND: "VERSO INDOOR",
    },
    footerLink: "www.iddero.com",
    customAvatar: true,
    voiceControl: true,
    voiceCompatible: ["HC3-KNX", "HC3L-KNX", "VERIP", "VERIND"]
  },
  eelectron: {
    cloudDomain: "eelectron.online",
    modelPrefix: "",
    modelNameMap: {
      VERIP: "TP43I11KNX",
      VERIND: "TP43I21KNX",
      "HC3-KNX": "TP70I12KNX",
      "HC3L-KNX": "TP10I12KNX",
    },
    footerLink: "www.eelectron.com",
    customAvatar: false,
    voiceControl: false,
    voiceCompatible: []
  },
};

export const oemFlavor = process.env.REACT_APP_OEM_FLAVOR || "iddero";

export const oemValues = oemValuesMap[oemFlavor];
