import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import { rootReducer } from "./store";
import { configureStore } from "@reduxjs/toolkit";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["voiceControl", "devices"],
  }

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export const persistor = persistStore(store)