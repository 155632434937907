import cn from "classnames";
import styles from "./DeviceStatusMessage.module.scss"
import { Button } from "../../components"
import { useDispatch, useSelector } from "react-redux";
import { getSelectedDeviceData } from "../../redux/voiceControl-slice";
import { Dialog } from "../../components";
import { useState } from "react";
import { useFormatMessage } from "../../i18n";
import { deviceStatus } from "../../constants/images";
import { 
    getInProgressState,
    refreshDeviceStatus,
    deleteControls,
    getControls } from "../../redux/voiceControl-slice";

export function DeviceStatusMessage() {

    const [showModal, setShowModal] = useState(false)
    const deviceSelected = useSelector(getSelectedDeviceData)
    const tr = useFormatMessage();
    const dispatch = useDispatch();
    const { deleteInProgress } = useSelector(getInProgressState)
    function handleDeleteControls() {
        dispatch(deleteControls(deviceSelected.cloudId))
        setShowModal(false)
    }

    function handleRefreshDevice() {
        dispatch(refreshDeviceStatus(deviceSelected.cloudId))
        .then((data) => {
            if(data.payload.device_status === "ok"){
                dispatch(getControls(deviceSelected.cloudId))
            }
        })
    }

    const errorMessages = {
        NOT_FOUND: "No se encuentra el dispositivo",
        TIME_OUT: "El dispositivo no responde"
    }

    const errorMessage = errorMessages[deviceSelected.error] || "Ha ocurrido un error"

    const infoMessages = {
        unselected: {
            icon: deviceStatus["unselected"],
            message: "Elige un dispositivo para comenzar",
        },
        "reprogrammed": {
            icon: deviceStatus["reprogrammed"],
            message: "Dispositivo reprogramado",
            className: styles.incompatible
        },
        "invalid_firmware": {
            icon: deviceStatus["invalid_firmware"],
            message: "Error de Firmware",
            className: styles.incompatible
        },
        "invalid_device": {
            icon: deviceStatus["invalid_device"],
            message: "Error de Firmware",
            className: styles.incompatible
        },
        "offline": {
            icon: deviceStatus["offline"],
            message: "Dispositivo offline: vuelva a sincronizarlo",
            className: styles.offline
        },
        checking: {
            icon: deviceStatus["checking"],
            message: "Comprobando...",
            className: styles.checking
        },
        error: {
            icon: deviceStatus["error"],
            message: errorMessage,
            className: styles.incompatible
        },
        empty: {
            icon: deviceStatus["empty"],
            message: "Proyecto vacio",
        }
    }

    const refreshStatus = ["invalid_firmware", "invalid_device", "offline", "error", "empty"]
    const deleteStatus = ["reprogrammed"]
    const infoMessage = infoMessages[deviceSelected.status]
    return (
        <div className={styles.DeviceStatusMessage}>
            <div className={cn(styles.statusIcon, infoMessage?.className)}>
                {infoMessage?.icon}
            </div>
            <div className={styles.textMessage}>
                {infoMessage?.message}
            </div>

            {deleteStatus.includes(deviceSelected.status) && (
                <Button
                    loading={deleteInProgress}
                    type="submit"
                    title={"Eliminar controles"}
                    disabled={false}
                    variant="danger"
                    className={styles.dangerButton}
                    onClick={() => { setShowModal(true) }}
                />)
            }
            {refreshStatus.includes(deviceSelected.status) && (
                <Button
                    loading={false}
                    type="submit"
                    title={"Sincronizar"}
                    disabled={false}
                    variant="primary"
                    className={styles.dangerButton}
                    onClick={handleRefreshDevice}
                />)
            }
            <Dialog
                content={"Esta acción no puede revertirse. ¿Seguro que quiere eliminar el control?"}
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                buttons={[
                    {
                        title: tr("no_go_back"),
                        handler: () => setShowModal(false),
                        variant: "light",
                    },
                    {
                        title: tr("yes_i_want_to_proceed"),
                        handler: handleDeleteControls,
                        variant: "danger",
                    },
                ]}
            />
        </div>
    )
}