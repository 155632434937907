import { Select } from "../../components";
import { getControlSection, getControlZone } from "../../utils";
import { Fragment } from "react";

export function ComponentSelect({
  components,
  formik,
  disabled,
  disabledClass,
  deviceSelected,
}) {
  function selectedTypeComponents(components) {
    const typeComponents = components.filter((control) => {
      return control?.possible_types.includes(parseInt(formik.values.type));
    });
    return typeComponents || [];
  }

  //Classify components in zones and sections
  function componentsByZone() {
    const zones = deviceSelected?.zones?.map((zone) => {
      const zoneComponents = selectedTypeComponents(components).filter(
        (control) => getControlZone(control.id) === zone.id,
      );
      return {
        ...zone,
        sections: zone.sections.map((section) => {
          const pageComponents = zoneComponents.filter((control) => {
            return getControlSection(control.id) === section.id;
          });

          return pageComponents.length
            ? { ...section, controls: pageComponents }
            : { empty: true };
        }),
      };
    });
    return zones;
  }

  //Transforms clasified components into select options
  function componentsToOptions() {
    const componentsOptions = componentsByZone()?.map((zone) => {
      return zone.sections.every((section) => section.empty) ? (
        <Fragment key={zone.id} />
      ) : (
        <Fragment key={zone.id}>
          <optgroup label={zone.name || zone.id} key={`zone-${zone.id}`}>
            {"_"}
          </optgroup>
          {zone.sections.map((section, idx) => {
            return section.empty ? (
              <Fragment key={`${zone.id}-${idx}`} />
            ) : (
              <optgroup
                label={`\u00A0\u00A0 Página ${section.name || section.id}`}
                key={`${zone.id}-${section.id}`}
              >
                {section.controls.map((control) => (
                  <option
                    value={JSON.stringify(control)}
                    key={`control-${control.id}`}
                  >
                    {control.name}
                  </option>
                ))}
              </optgroup>
            );
          })}
        </Fragment>
      );
    });
    return componentsOptions;
  };

  function placeHolder() {
    return <option>No hay componentes</option>;
  }
  const componentOptions = componentsToOptions();
  
  return (
    <Select
      name="component"
      onChange={formik.handleChange}
      options={!selectedTypeComponents(components)?.length ? placeHolder() : componentOptions}
      optionsState={formik.values.component}
      label={"Componentes"}
      disabledClass={disabledClass}
      disabled={disabled}
    />
  );
}
