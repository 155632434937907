import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.scss";
import App from "./App";
import { persistor } from "./redux/persist-store";
import { redirectFromOldUrls } from "./services/redirect-service";
import { Provider } from "react-redux";
import { ErrorBoundary, ScrollToTop } from "./components";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "./redux/store";

redirectFromOldUrls();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
