import styles from "./DeviceCardGridView.module.scss";
import sharedStyles from "../../shared.module.scss";
import { ReactComponent as SettingsIcon } from "../../assets/icons/device-setting-icon.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/device-media-control.svg";
import { ReactComponent as VoiceControlIcon } from "../../assets/icons/micro-icon.svg";
import { Link } from "../../components";
import { deviceImages } from "../../constants/images";
import { oemValues } from "../../constants/oem";
import { isDeviceClickable, getModelName } from "../../utils";
import { selectDevice } from "../../redux/voiceControl-slice";
import { useDispatch } from "react-redux";
import cn from "classnames";

export function DeviceCardGridView({ device }) {

  const dispatch = useDispatch()
  function handleDeviceCardClick() {
    if (isDeviceClickable(device.model)) return;
    window.open(`https://${device.cloudId}.${oemValues.cloudDomain}`, "_blank");
  }

  return (
    <div
      className={styles.deviceCard}
      onClick={handleDeviceCardClick}
      style={{
        backgroundImage: `url(${deviceImages[device.model]}`,
        cursor: !isDeviceClickable(device.model) ? "pointer" : "default",
      }}
    >
      <div className={styles.deviceCardWrapper}>
        <div className={cn(styles.deviceModel, sharedStyles.secondaryText)}>
          {getModelName(device.model)}
        </div>
        <div className={cn(styles.deviceName, sharedStyles.formText)}>
          {getModelName(device.model, device.friendly_name)}
        </div>
        <div className={styles.deviceBtnGroup}>
          <Link
            to={`/device-settings/${device.cloudId}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.deviceIconLink}
          >
            <SettingsIcon className={styles.deviceSettingsIcon} />
          </Link>
          {oemValues.voiceCompatible.includes(device.model) && (
            <Link to="/voice"
              onClick={(e) => {
                dispatch(selectDevice(device))
                e.stopPropagation();
              }}
              className={styles.deviceIconLink}>
              <VoiceControlIcon className={styles.deviceMicroIcon} />
            </Link>
          )}
          {!isDeviceClickable(device.model) && (
            <PlayIcon className={styles.devicePlayIcon} />
          )}
        </div>
      </div>
    </div>
  );
}
