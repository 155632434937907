import { Link } from "../components";
import cn from "classnames";
import styles from "./InfoPage.module.scss";
import sharedStyles from "../shared.module.scss";

export function InfoPage({
  onBackClick,
  title,
  page,
  linkText,
  pageClassName,
  contentClassName,
}) {
  return (
    <div className={cn(styles.infoPage, pageClassName)}>
      <div className={cn(styles.headerContainer, sharedStyles.content)}>
        <Link
          onClick={onBackClick}
          className={cn(sharedStyles.backLink, sharedStyles.secondaryText)}
        >
          {linkText}
        </Link>
        {title && (
          <h1 className={sharedStyles.pageTitle}>{title}</h1>
        )}
      </div>

      <div className={styles.contentContainer}>
        <div
          className={cn(sharedStyles.content, styles.content, contentClassName)}
          dangerouslySetInnerHTML={{ __html: page }}
        />
      </div>
    </div>
  );
}
