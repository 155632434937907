import {
  validateRequired,
  validateMinLen,
  validateEmail,
  validatePassLowLetter,
  validatePassUpperLeter,
  validatePassDigit,
  validateDifferrent,
  validateEqual,
  validateMaxLen,
  validateType
} from "../services/validation-functions";
import { SERVER_ERRORS } from "../api/idderocloud-api";

const passwordIsRequired = [
  {
    fn: validateRequired,
    errorMessage: "you_have_to_populate_the_password",
    fieldName: "password",
  },
];

const currentPasswordIsRequired = [
  {
    fn: validateRequired,
    errorMessage: "you_have_to_populate_the_password",
    fieldName: "currentPassword",
  },
];

const passwordValidations = [
  ...passwordIsRequired,
  {
    fn: validateMinLen,
    errorMessage: "password_must_contain_at_least_eight_characters",
    fieldName: "password",
  },
  {
    fn: validatePassLowLetter,
    errorMessage: "password_must_contain_at_least_one_lowercase_letter_a-z",
    fieldName: "password",
  },
  {
    fn: validatePassUpperLeter,
    errorMessage: "password_must_contain_at_least_one_uppercase_letter_a-z",
    fieldName: "password",
  },
  {
    fn: validatePassDigit,
    errorMessage: "password_must_contain_at_least_one_number_-",
    fieldName: "password",
  },
  {
    fn: validateEqual,
    errorMessage: "please_check_that_youve_entered_and_confirmed_your_password",
    fieldNames: ["confirmPassword", "password"],
  },
  {
    fn: validateDifferrent,
    errorMessage: "password_must_be_different_from_username",
    fieldNames: ["password", "name"],
  },
];

const nameValidation = [
  {
    fn: validateRequired,
    errorMessage: "name_is_mandatory",
    fieldName: "name",
  },
  {
    fn: validateDifferrent,
    errorMessage: "password_must_be_different_from_username",
    fieldNames: ["password", "name"],
  },
];

const emailIsRequired = [
  {
    fn: validateRequired,
    errorMessage: "you_have_to_populate_your_email_before",
    fieldName: "email",
  },
];
const emailValidation = [
  ...emailIsRequired,
  {
    fn: validateEmail,
    errorMessage: "please_enter_a_valid_email_ie_maildomaincom",
    fieldName: "email",
  },
];

const countryValidation = [
  {
    fn: validateRequired,
    errorMessage: "country_is_required",
    fieldName: "country",
  },
];

const termsValidation = [
  {
    fn: validateRequired,
    errorMessage: "you_must_accept_terms_and_conditions",
    fieldName: "acceptTerms",
  },
];

const serverValidation = [
  {
    serverFn: (errors) => !errors.includes(SERVER_ERRORS.USER_ALREADY_EXISTS),
    errorMessage:
      "this_email_already_exists_please_select_another_one_email_must_be_unique",
    fieldName: "email",
    canResubmit: true,
  },
];

const changePassServerValidation = [
  {
    serverFn: (errors) => !errors.includes(SERVER_ERRORS.INCORRECT_PASSWORD),
    errorMessage: "incorrect_password",
    fieldName: "currentPassword",
    canResubmit: true,
  },
];

const friendlyNameValidation = [
  {
    fn: validateRequired,
    errorMessage: "name_is_mandatory",
    fieldName: "name",
  },
  {
    fn: validateMaxLen,
    errorMessage: "name_is_too_long",
    fieldName: "name",
  }
];

const typeValidation = [
  {
    fn: validateRequired,
    errorMessage: "type_is_mandatory",
    fieldName: "type",
  },
  {
    fn: validateType,
    errorMessage: "type_is_not_valid",
    fieldName: "type",
  }
];


export const singupValidation = [
  ...serverValidation,
  ...termsValidation,
  ...countryValidation,
  ...emailValidation,
  ...nameValidation,
  ...passwordValidations,
];

export const changePasswordValidation = [
  ...passwordValidations,
  ...currentPasswordIsRequired,
  ...changePassServerValidation,
];

export const passwordRecoveryValidation = [...emailValidation];
export const loginValidation = [...emailIsRequired, ...passwordIsRequired];

export const userSettingsValidation = [...countryValidation, ...nameValidation];

export const controlValidation = [...friendlyNameValidation, ...typeValidation];