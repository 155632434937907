import cn from "classnames";
import { ComponentSelect, TypeSelect, ValidatedInput } from "./components";
import { useHistory } from "react-router-dom";
import styles from "./EditControlPage.module.scss";
import { useFormik } from "formik";
import sharedStyles from "../shared.module.scss";
import { useFormatMessage } from "../i18n";
import { Button, Dialog } from "../components";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { scrollToFirstValidationError } from "../utils";
import { controlValidation } from "../shared/validation";
import { getErrorMsg, validateForm, isValid } from "../services/validation-service";
import {
    getInProgressState,
    addControl,
    deleteControl,
    getError,
    initEdit,
    getControls,
    getDeviceVoiceData,
} from "../redux/voiceControl-slice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getDevice, getUserDeviceState, initDeviceDetails } from "../redux/devices-slice";
import Skeleton from "react-loading-skeleton";
import { Alert } from "../components";

export const EditControlPage = function () {

        
    const { cloudId, controlId } = useParams();
    const inProgress = useSelector(getInProgressState)
    const error = useSelector(getError)
    const history = useHistory();
    const tr = useFormatMessage();
    const dispatch = useDispatch();

    const { 
        userDevice,
        userDeviceError,
    } = useSelector(getUserDeviceState)
    const voiceDevice = useSelector(getDeviceVoiceData(cloudId))
    const [showModal, setShowModal] = useState(false);
    const [pristine, setPristine] = useState(true)
    
    //Cached function to not change dependencies of useEffect on every array
    const findFirstOfType = useCallback((type) => {
        const component = voiceDevice?.not_configured?.find(
            (control) => {
                return control.possible_types.includes(parseInt(type))
            }
        )
        return JSON.stringify(component)
    }, [voiceDevice])

    const formik = useFormik({
        initialValues: {
            name: "",
            type: "",
            component: ""
        },
        onSubmit: async values => {
            setPristine(false);
            const { name, type, component } = values
            if (isValid(validationErrors)) {
                const data = await dispatch(addControl({
                    cloudId: userDevice.cloudId,
                    control: {
                        name: name.trim(),
                        type: parseInt(type),
                        component: component,
                    }
                })
                )
                if (data.meta.requestStatus === "fulfilled") {
                    handleSaveClick()
                }
            } else {
                scrollToFirstValidationError();
            }
        }
    });

    useEffect(() => {
        dispatch(initEdit())
        dispatch(initDeviceDetails())
        dispatch(getDevice(cloudId))
        .then(() => {dispatch(getControls(cloudId))})
    }, [dispatch, cloudId])

    useEffect(() => {
        if (voiceDevice) {
            const control = voiceDevice.configured.find((control) => control.id === controlId);
                if (control) {
                    formik.setFieldValue("name", control?.friendly_name || control?.name)
                    formik.setFieldValue("type", String(control?.type))
                    formik.setFieldValue("component", JSON.stringify(control))
                }else{
                    formik.setFieldValue("type", String(voiceDevice?.not_configured[0]?.possible_types[0]))
                    formik.setFieldValue("component", findFirstOfType(voiceDevice?.not_configured[0]?.possible_types[0]))
                }
        }
    }, [voiceDevice, controlId, findFirstOfType])

    function handleSaveClick() {
        history.push("/voice/deviceControls")
    }

    function initialControl(controlId){
        return controlId && voiceDevice
            ? voiceDevice?.configured?.find((control) => control.id === controlId)
            : voiceDevice?.not_configured[0]
    }

    function controlOptions(){
        if(!voiceDevice) {return []}
        return (!controlId || !initialControl(controlId)) ? voiceDevice?.not_configured : [initialControl(controlId)]
    }

    async function handleDeleteControl() {
        setShowModal(false)
        const data = await dispatch(deleteControl({
            cloudId: cloudId,
            controlId: controlId
        }))
        if (data.meta.requestStatus === "fulfilled") {
            handleSaveClick()
        }
    }

    function isActionInProgress() {
        return inProgress.addInProgress || inProgress.deleteInProgress
    }

    const errorMessages = {
        INVALID_REQUEST: "Error en nombre o tipo"
    }

    const validationErrors = validateForm(
        { form: formik.values },
        controlValidation
    );
    const nameErrorMessage = getErrorMsg(validationErrors, "name")
    const nameTypeMessage = getErrorMsg(validationErrors, "type")

    return (
        <div className={cn(sharedStyles.content, styles.editControlPage)}>
            <div className={cn(sharedStyles.headerContainer, sharedStyles.content, styles.headerContainer)}>
                <h1 className={sharedStyles.pageTitle}>{controlId ? initialControl(controlId)?.friendly_name : "Añadir control"}</h1>
            </div>
            {userDeviceError && (
                <Alert severity="error" className={styles.editControlError}>
                    {tr("fail_getting_device")}
                </Alert>
            )}
            <form className={styles.addNewControlForm} onSubmit={formik.handleSubmit}>
                <ValidatedInput
                    label={"Nombre"}
                    id="name-input"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    errors={nameErrorMessage}
                    pristine={pristine}
                />

                <label className={cn(sharedStyles.formtext, styles.label)}>Tipo</label>
                {voiceDevice?.loading
                    ? <Skeleton className={styles.skeleton}/>
                    : <TypeSelect
                        deviceComponents={controlOptions()}
                        formik={formik}
                        findFirstOfType={findFirstOfType}
                        isDisabled={controlId || !voiceDevice}
                        className={(controlId || !voiceDevice) && styles.disabled}
                        pristine={pristine}
                        errors={nameTypeMessage}
                    />}

                {voiceDevice?.loading
                ? <Skeleton className={styles.skeleton}/>
                : <ComponentSelect
                        formik={formik}
                        components={controlOptions()}
                        disabledClass={(controlId || !voiceDevice) && styles.disabled}
                        disabled={controlId || !voiceDevice}
                        deviceSelected={voiceDevice}
                    />}
                {error && (
                    <Alert severity="error" className={styles.editControlError}>
                        <span>{errorMessages[voiceDevice.error] || "Ha ocurrido un error"}</span>
                    </Alert>
                )}

                <div className={styles.buttonsContainer}>
                    <Button
                        loading={inProgress.addInProgress}
                        className={styles.button}
                        type="submit"
                        title={"Guardar"}
                        disabled={!formik.values.component || isActionInProgress()}
                        variant="primary"
                    />
                    {controlId && (
                        <Button
                            loading={inProgress.deleteInProgress}
                            className={styles.button}
                            type="button"
                            title={"Eliminar control"}
                            disabled={!formik.values.component || isActionInProgress()}
                            variant="danger"
                            onClick={() => { setShowModal(true) }}
                        />
                    )}
                </div>
            </form>
            <Dialog
                content={"Esta acción no puede revertirse. ¿Seguro que quiere eliminar el control?"}
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                buttons={[
                    {
                        title: tr("no_go_back"),
                        handler: () => setShowModal(false),
                        variant: "light",
                    },
                    {
                        title: tr("yes_i_want_to_proceed"),
                        handler: () => { handleDeleteControl() },
                        variant: "danger",
                    },
                ]}
            />
        </div>
    )
}