import styles from "./VoiceDeviceCardMobile.module.scss"
import { ReactComponent as DropDownIcon } from "../../assets/icons/select-arrow.svg";
import { useDispatch } from "react-redux";
import { selectDevice, getControls, getDeviceVoiceData } from "../../redux/voiceControl-slice";
import { deviceStatus } from "../../constants/images";
import { Link } from "../../components";
import { useSelector } from "react-redux";
import { getModelName } from "../../utils";

export function VoiceDeviceCardMobile({ device }) {

    const deviceData = useSelector(getDeviceVoiceData(device.cloudId))
    const dispatch = useDispatch();

    function onSelectDevice(device) {
        dispatch(selectDevice(device))
        if(deviceData.status === "ok"){
            dispatch(getControls(device.cloudId))
        }
    }

    return (
            <div className={styles.voiceControlDeviceCard} key={device.id}>
                <div className={styles.deviceInfo}>
                    <div className={styles.deviceModel}>
                        {getModelName(device.model)}
                    </div>
                    <div className={styles.deviceFriendlyName}>
                        {getModelName(device.model, device.friendly_name)}
                    </div>
                </div>
                <div className={styles.deviceStatus}>
                    {deviceStatus[`${deviceData?.status}`]}
                </div>
                <Link 
                    to={{pathname:"/voice/deviceControls", state: {...deviceData, ...device}}}
                    className={styles.arrow} 
                    onClick={() => { onSelectDevice(device)}}>
                    <DropDownIcon />
                </Link>
            </div>
    )
}