import styles from "./VoiceControlPage.module.scss";
import sharedStyles from "../shared.module.scss";
import cn from "classnames";
import { DeviceControlsContainer } from "./components"
import { useDispatch, useSelector } from "react-redux";
import { getSelectedDeviceData } from "../redux/voiceControl-slice";
import { refreshDeviceStatus} from "../redux/voiceControl-slice";
import { getModelName } from "../utils";
import { useEffect } from "react";

export function DeviceControlsPage() {

    const dispatch = useDispatch()
    const deviceSelected = useSelector(getSelectedDeviceData)

    useEffect(() => {
        if(deviceSelected.status === ""){
            dispatch(refreshDeviceStatus(deviceSelected.cloudId))
        }
    }, [dispatch, deviceSelected])

    const title = deviceSelected.hasOwnProperty("cloudId") ? getModelName(deviceSelected?.model, deviceSelected?.friendly_name) : "No seleccionado"

    return (
        <div className={cn(sharedStyles.content, styles.voiceControlPage)}>
            <div className={cn(sharedStyles.headerContainer, sharedStyles.content, styles.headerContainer)}>
                <h1 className={sharedStyles.pageTitle}>{title}</h1>
            </div>
            <DeviceControlsContainer/>
        </div>
    )
}