import styles from "./VoiceControlPage.module.scss";
import sharedStyles from "../shared.module.scss";
import cn from "classnames";
import { VoiceDevicesList, DeviceControlsContainer } from "./components"
import Media from "react-media";

export function VoiceControlPage() {
    return (
        <div className={cn(sharedStyles.content, styles.voiceControlPage)}>
            <div className={cn(sharedStyles.headerContainer, sharedStyles.content, styles.headerContainer)}>
                <h1 className={sharedStyles.pageTitle}>Control por voz</h1>
            </div>

            <Media query="(max-width: 576px)">
                {matches => matches
                    ? (
                        <VoiceDevicesList
                            isMobile={true} />
                    )
                    : (
                        <div className={styles.voiceControlMenu}>
                            <VoiceDevicesList
                                className={styles.voiceControlContainer} />
                            <DeviceControlsContainer
                                className={styles.voiceControlContainer} />
                        </div>
                    )}
            </Media>
        </div>
    )
}