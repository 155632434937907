import styles from "./DeviceControlCard.module.scss"
import { ReactComponent as DeviceSettingIcon } from "../../assets/icons/device-setting-icon.svg";
import { controlTypes } from "../../constants/images";
import { Link } from "../../components";
import { useSelector } from "react-redux";
import { getSelectedDeviceData } from "../../redux/voiceControl-slice";

export function DeviceControlCard({control}) {
    const deviceSelected = useSelector(getSelectedDeviceData)
    return(
        <div className={styles.controlCard}>
            <div className={styles.controlIcon}>
                <img src={controlTypes[control.type]} alt={control.type}/>
            </div>
            <div className={styles.controlName}>
                {control.friendly_name || control.name}
            </div>
            <div className={styles.editControlIcon}>
                <Link 
                    to={{pathname: `/voice/editControl/${deviceSelected.cloudId}/${control.id}`, state: {control, deviceSelected}}}>
                    <DeviceSettingIcon />
                </Link>
            </div>
        </div>
    )
}