import { default as ReactSelect, components } from "react-select"
import styles from "./TypeSelect.module.scss";
import cn from "classnames";
import sharedStyles from "../../shared.module.scss";
import {errorsBox, errorBorder} from "./shared.module.scss"
import { controlTypes } from "../../constants/images";
import { ValidationErrors } from "./ValidationErrors";

export function TypeSelect({
    deviceComponents,
    formik,
    findFirstOfType,
    isDisabled,
    className,
    pristine,
    errors,
}) {

    const typeOptions = [
        { value: "0", label: "Interruptor", icon: controlTypes[0], type: "switch" },
        { value: "1", label: "Luz no regulable", icon: controlTypes[1], type: "noRegLight" },
        { value: "2", label: "Luz regulable", icon: controlTypes[2], type: "regLight" },
        { value: "3", label: "Luz RGB", icon: controlTypes[3], type: "rgbLight" },
        { value: "4", label: "Indicador de temperatura", icon: controlTypes[4], type: "tempIndicator" },
        { value: "5", label: "Termostato", icon: controlTypes[5], type: "thermostat" },
        { value: "6", label: "Persiana", icon: controlTypes[6], type: "blinds" },
        { value: "7", label: "Escena", icon: controlTypes[7], type: "scene" },
    ];

    //All available types from non-configured components
    function possibleTypes() {
        const components = deviceComponents || []
        const possibleTypes = components.map(
            (control) => {
                return control?.possible_types
            }
        )
        const allPossibleTypes = possibleTypes.flat()
        return allPossibleTypes;
    }

    //Type options filtered by available types -> select options
    const availableOptions = typeOptions.filter(
        (option) => possibleTypes().includes(parseInt(option.value))
    )

    const { Option, SingleValue } = components;

    //Modified component for react-select to get icons
    const IconOption = props => (
        <Option {...props}>
            <img src={props.data.icon} className={styles.typeIcon} alt={props.data.type} />
            {props.data.label}
        </Option>
    );

    //Modified component for react-select to get icons
    const SingleValueIcon = (props) => (
        <SingleValue {...props}>
            <img src={typeOptions[props.data.value].icon} className={styles.typeIcon} alt={typeOptions[props.data.value].type} />
            <div className={styles.selectedText}>{typeOptions[props.data.value].label}</div>
        </SingleValue>
    )

    const showErrors = !pristine && errors.length !== 0;
    
    return (
        <>
        <div className={cn(styles.selectWrapper, styles.marginBottom, className)}>
            <ReactSelect
                isDisabled={isDisabled}
                placeholder="Selecciona un tipo"
                name="type"
                options={availableOptions}
                components={{
                    Option: IconOption,
                    SingleValue: SingleValueIcon,
                    DropdownIndicator: null,
                    IndicatorSeparator: null
                }}
                unstyled
                classNames={{
                    control: () => styles.control,
                    menuList: () => styles.menuList,
                    option: () => styles.option,
                    singleValue: () => styles.singleValue,
                    placeholder: () => styles.placeHolder
                }}
                className={cn(sharedStyles.formText, styles.select, showErrors && errorBorder)}
                isSearchable={false}
                hideSelectedOptions
                defaultValue={availableOptions.find(
                    ({ value }) => value === formik.values.type
                )}
                value={availableOptions.find(
                    ({ value }) => value === formik.values.type
                )}
                onChange={(option) => {
                    formik.setFieldValue("type", option.value)
                    formik.setFieldValue("component", findFirstOfType(option.value))
                }} />
        </div>
              {!pristine && (
                <div className={cn(showErrors && errorsBox)}>
                  <ValidationErrors errors={errors} />
                </div>
              )}
        </>
    )
}