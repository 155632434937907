import React, { useCallback, useEffect, useState } from "react";
import { StatusPage } from ".";
import { useFormatMessage } from "../i18n";
import { useDispatch } from "react-redux";
import { confirmForgotPassword } from "../redux/auth-slice";
import { redirectToLogin } from "../services/auth-service";
import { getQueryParam } from "../utils";

export function ConfirmForgotPasswordPage() {
  const tr = useFormatMessage();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("");
  const confirmation_code = getQueryParam("cpcode");

  const handleSubmit = useCallback(
    async (confirmation_code) => {
      const data = await dispatch(confirmForgotPassword({ confirmation_code }));

      if (data.meta.requestStatus === "fulfilled") {
        setMessage(tr("this_is_your_new_password") + data.payload.new_password);
        setVariant("success");
      } else {
        setVariant("error");
        setMessage(tr("sorry_but_we_cannot_change_your_password"));
      }
    },
    [dispatch, tr]
  );

  useEffect(() => {
    if (confirmation_code) {
      handleSubmit(confirmation_code);
    } else {
      redirectToLogin();
    }
  }, [confirmation_code, handleSubmit]);

  return (
    <>
      <StatusPage text={message} variant={variant} />
    </>
  );
}
