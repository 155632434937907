import { useFormik } from "formik";
import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import { useHistory, useLocation } from "react-router";
import { useFormatMessage } from "../i18n";
import { Button, Select, Link, Alert } from "../components";
import styles from "./SignUpPage.module.scss";
import sharedStyles from "../shared.module.scss";
import {
  validateForm,
  getErrorMsg,
  isValid,
} from "../services/validation-service";
import { singupValidation } from "../shared/validation";
import {
  ValidatedCheckbox,
  ValidatedInput,
  ValidatedSelect,
} from "./components";
import { useDispatch, useSelector } from "react-redux";
import {
  getSignupState,
  initServerError,
  initSignup,
  signup,
} from "../redux/auth-slice";
import { Recaptcha } from "./components/Recaptcha";
import { TermsPage } from "./TermsPage";
import { StatusPage } from "./StatusPage";
import { useCountriesOptions } from "../hooks/useCountries";
import { useLanguagesOptions } from "../hooks/useLanguages";
import { scrollToFirstValidationError } from "../utils";

export function SignUpPage() {
  const tr = useFormatMessage();
  const dispatch = useDispatch();
  const { serverErrors, signupInProgress, signupFailed } = useSelector(
    getSignupState
  );
  const [stage, setStage] = useState("signup");
  const history = useHistory();
  const location = useLocation();
  const countriesOptions = useCountriesOptions();
  const languagesOptions = useLanguagesOptions();
  const recaptchaRef = useRef();

  useEffect(() => {
    dispatch(initSignup());
  }, [dispatch]);

  useEffect(() => {
    if (location.pathname === "/terms-and-conditions") {
      setStage("terms");
    }
    if (location.pathname === "/signup") {
      setStage("signup");
    }
  }, [location.pathname]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      name: "",
      country: "",
      language: "en",
      acceptTerms: false,
    },

    onSubmit: async (values) => {
      setPristine(false);
      const { email, password, name, country, language } = values;
      if (isValid(validationErrors) && recaptcha) {
        const data = await dispatch(
          signup({
            username: email,
            lang: language,
            password,
            name,
            country,
            language,
            recaptcha_response: recaptcha,
          })
        );
        if (data.meta.requestStatus === "fulfilled") {
          setStage("success");
        } else {
          setRecaptcha("");
          recaptchaRef.current?.reset();
          scrollToFirstValidationError();
        }
      } else {
        scrollToFirstValidationError();
      }
    },
  });

  useEffect(() => {
    if (serverErrors.length) {
      dispatch(initServerError());
    }
    // eslint-disable-next-line
  }, [formik.values.email, dispatch]);

  const [recaptcha, setRecaptcha] = useState("");
  const [pristine, setPristine] = useState(true);

  const onChange = (value) => {
    setRecaptcha(value);
  };

  const validationErrors = validateForm(
    { form: formik.values, serverErrors },
    singupValidation
  );

  function handleTermsClick() {
    history.push("/terms-and-conditions");
  }

  function handleBackClick() {
    history.goBack();
  }

  function handleCancelClick() {
    history.replace("/login");
  }

  const emailErrorMsg = getErrorMsg(validationErrors, "email");
  const passErrorMsg = getErrorMsg(validationErrors, "password");
  const confirmPassErrorMsg = getErrorMsg(validationErrors, "confirmPassword");
  const nameErrorMsg = getErrorMsg(validationErrors, "name");
  const acceptErrorMsg = getErrorMsg(validationErrors, "acceptTerms");
  const countryErrorMsg = getErrorMsg(validationErrors, "country");

  return (
    <>
      {stage === "terms" && <TermsPage onBackClick={handleBackClick} />}
      {stage === "success" && (
        <StatusPage
          text={tr(
            "you_will_receive_an_email_soon_please_click_on_the_activation_url"
          )}
          variant="success"
        />
      )}
      <div
        className={cn(
          stage !== "signup" && styles.hidden,
          styles.signupPage,
          sharedStyles.content
        )}
      >
        <h1 className={cn(sharedStyles.title, sharedStyles.pageTitle)}>
          {tr("registration_form")}
        </h1>
        <form
          onSubmit={formik.handleSubmit}
          className={cn(styles.form, sharedStyles.form)}
        >
          {/* https://stackoverflow.com/questions/15738259/disabling-chrome-autofill */}
          <div style={{ height: 0, overflow: "hidden" }}>
            {" "}
            <input type="text" name="email" tabIndex="-1" />{" "}
            <input type="password" name="password" tabIndex="-1" />{" "}
          </div>
          <ValidatedInput
            label={tr("email")}
            id="email-input"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            errors={emailErrorMsg}
            pristine={pristine}
          />
          <ValidatedInput
            label={tr("password")}
            id="password-input"
            name="password"
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            errors={passErrorMsg}
            pristine={pristine}
          />
          <ValidatedInput
            label={tr("confirm_password")}
            id="confirmPasword-input"
            type="password"
            name="confirmPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            errors={confirmPassErrorMsg}
            pristine={pristine}
          />
          <ValidatedInput
            label={tr("name")}
            id="name-input"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            errors={nameErrorMsg}
            pristine={pristine}
          />
          <ValidatedSelect
            name="country"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={countriesOptions}
            defaultName={tr("country")}
            optionsState={formik.values.country}
            pristine={pristine}
            errors={countryErrorMsg}
            label={tr("country")}
          />
          <Select
            name="language"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={languagesOptions}
            optionsState={formik.values.language}
            label={tr("default_language")}
          />
          <div className={styles.containerForLinks}>
            <ValidatedCheckbox
              label={tr("accept_terms_and_conditions")}
              id="acceptTerms-input"
              name="acceptTerms"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.acceptTerms}
              errors={acceptErrorMsg}
              pristine={pristine}
            />
            <Link
              className={cn(styles.link, styles.termsLink)}
              onClick={handleTermsClick}
            >
              {tr("see_conditions")}
            </Link>
          </div>

          <Recaptcha
            rref={recaptchaRef}
            onChange={onChange}
            pristine={pristine}
            recaptcha={recaptcha}
          />
          {signupFailed && (
            <Alert severity="error" className={styles.alert}>
              {tr("something_went_wrong")}
            </Alert>
          )}
          <Button
            type="submit"
            className={styles.button}
            title={tr("register")}
            variant="primary"
            loading={signupInProgress}
          />
          <Link onClick={handleCancelClick} className={styles.link}>
            {tr("cancel")}
          </Link>
        </form>
      </div>
    </>
  );
}
