import styles from "./DeviceControlsContainer.module.scss"
import { DeviceStatusMessage, DeviceControlsList } from "./"
import cn from "classnames";
import { useSelector } from "react-redux";
import { getSelectedDeviceData } from "../../redux/voiceControl-slice";


export function DeviceControlsContainer({className}) {

    /*
    const selected = useSelector(getSelectedDeviceData)
    const { state } = useLocation(); //Delete if keep redux-persit
    //state comes only in mobile version
    //Delete State if keep redux-persit
    const deviceSelected = selected || state
    */
   const deviceSelected = useSelector(getSelectedDeviceData)

    return (
        <div className={cn(styles.deviceControls, className)}>
            {deviceSelected.status === "ok"
                ? <DeviceControlsList />
                :<DeviceStatusMessage/>
            }
        </div>
    )
}