/* eslint-disable */
import HC3KNX from "!!url-loader!../assets/icons/device-images/HC3-KNX.svg";
import HC3LKNX from "!!url-loader!../assets/icons/device-images/HC3L-KNX.svg";
import HC2KNX from "!!url-loader!../assets/icons/device-images/HC2-KNX.svg";
import HC2LKNX from "!!url-loader!../assets/icons/device-images/HC2L-KNX.svg";
import HC1iKNX from "!!url-loader!../assets/icons/device-images/HC1i-KNX.svg";
import SER1KNX from "!!url-loader!../assets/icons/device-images/SER1-KNX.svg";
import VERIP from "!!url-loader!../assets/icons/device-images/VERIP.svg";

import Switch from "!!url-loader!../assets/icons/device-controls/switch.svg";
import NoRegLight from "!!url-loader!../assets/icons/device-controls/noRegLight.svg";
import RegLight from "!!url-loader!../assets/icons/device-controls/regLight.svg";
import TempIndicator from "!!url-loader!../assets/icons/device-controls/tempIndicator.svg";
import Thermostat from "!!url-loader!../assets/icons/device-controls/thermostat.svg";
import Blinds from "!!url-loader!../assets/icons/device-controls/blinds.svg";
import Scene from "!!url-loader!../assets/icons/device-controls/scene.svg";
import RGBLight from "!!url-loader!../assets/icons/device-controls/rgbLight.svg";
/* eslint-enable */

import {ReactComponent as Ready} from "../assets/icons/device-status/device-ready.svg";
import {ReactComponent as Offline} from "../assets/icons/device-status/device-offline.svg";
import {ReactComponent as Incompatible} from "../assets/icons/device-status/device-incompatible.svg";
import {ReactComponent as Exclamation} from "../assets/icons/device-status/device-exclamation.svg";

import { Spinner } from "../components/Spinner";

export const deviceImages = {
  "HC3-KNX": HC3KNX,
  "HC3L-KNX": HC3LKNX,
  "HC2-KNX": HC2KNX,
  "HC2L-KNX": HC2LKNX,
  "HC1i-KNX": HC1iKNX,
  "SER1-KNX": SER1KNX,
  VERIP: VERIP,
  VERIND: VERIP,
};

export const deviceStatus = {
  "unselected": <Exclamation/>,
  "ok": <Ready/>,
  "reprogrammed": <Incompatible/>,
  "invalid_firmware": <Incompatible/>,
  "invalid_device": <Incompatible/>,
  "offline": <Offline/>,
  "checking": <Spinner/>,
  "error": <Incompatible/>,
  "empty": <Exclamation/>,
}

export const controlTypes = {
  0: Switch,
  1: NoRegLight,
  2: RegLight,
  3: RGBLight,
  4: TempIndicator,
  5: Thermostat,
  6: Blinds,
  7: Scene
}

export const devicesWithoutPlayIcon = ["VERIP", "VERIND"];
