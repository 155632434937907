import cn from "classnames"
import styles from "./VoiceDevicesList.module.scss"
import { VoiceDeviceCard, VoiceDeviceCardMobile } from "."
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { oemValues } from "../../constants/oem";
import {
    getDevices,
    devicesLoaded,
    getUserDevices,
    getDevicesFailed } from "../../redux/devices-slice";

export function VoiceDevicesList({ isMobile, className }) {

    const dispatch = useDispatch()
    const devices = useSelector(getDevices)
    const loaded = useSelector(devicesLoaded)
    const failed = useSelector(getDevicesFailed)

    const SKELETONS_DEVICE_COUNT = 4
    const skeletons = Array(SKELETONS_DEVICE_COUNT)
        .fill()
        .map((_, idx) => <Skeleton key={idx} className={styles.skeleton} />);

    useEffect(() => {
        dispatch(getUserDevices())
    }, [dispatch]);

    function listDevices(devices, isMobile) {
        const voiceCompatibleDevices = devices.filter((device) =>{
            return  oemValues.voiceCompatible.includes(device.model)})
        return isMobile
            ? voiceCompatibleDevices.map((device) => {
                return (
                    <VoiceDeviceCardMobile
                        device={device}
                        key={device.cloudId} />
                )
            })
            : voiceCompatibleDevices.map((device) => {
                return (
                    <VoiceDeviceCard
                        device={device}
                        key={device.cloudId} />
                )
            })
    }

    return (
        <div className={cn(styles.devicesList, className)}>
            {!failed
                ? loaded 
                    ? listDevices(devices, isMobile) 
                    : skeletons
                : <></>}
        </div>
    )
}