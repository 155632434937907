import styles from "./DeviceControlsList.module.scss"
import cn from "classnames";
import { DeviceControlCard } from "./DeviceControlCard";
import { Link } from "../../components";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as AddNewDeviceIcon } from "../../assets/icons/add-device-icon.svg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getControls, getSelectedDeviceData } from "../../redux/voiceControl-slice";

export function DeviceControlsList() {

    const SKELETONS_DEVICE_COUNT = 4;
    const dispatch = useDispatch()
    const deviceSelected = useSelector(getSelectedDeviceData)

    const skeletons = Array(SKELETONS_DEVICE_COUNT)
        .fill()
        .map((_, idx) => <Skeleton key={idx} className={styles.skeleton} />);

    useEffect(() => {
        if(deviceSelected.status === "ok" && !deviceSelected.loaded){
            dispatch(getControls(deviceSelected.cloudId))
        }
    }, [deviceSelected, dispatch])

    const controls = [...deviceSelected.configured].sort(
        (prevControl, nextControl) => parseInt(prevControl.id) - parseInt(nextControl.id)
    )
    return (
        <div className={cn(styles.controlList, styles.list)}>

            {
                !deviceSelected.loading
                    ?
                    <>
                        {controls.map(
                            (control) => <DeviceControlCard control={control} key={control.id}/>)}
                        <div key={"add"}>
                            <Link className={styles.addNewControlLink} to={{pathname:`/voice/editControl/${deviceSelected.cloudId}`, state: {deviceSelected}}} >
                                <div className={styles.addNewControlIcon}>
                                    <AddNewDeviceIcon />
                                </div>
                                <div className={styles.linkText}>
                                    Añadir Control
                                </div>
                            </Link>
                        </div>
                    </>
                    :
                    skeletons
            }
        </div>
    )
}